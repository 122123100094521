<template>
    <div class="indexcontent" id="indexcontent">
        

        <input type="text" class="input3" placeholder="输入我的手机号">

        <input type="text" class="input4" placeholder="输入我的手机号1">
        <div>v3</div>
        <div v-if="isWindow">window 滚动了</div>
        <div v-if="isDoc">document滚动了</div>
        <div class="chamfer"></div>
        <input type="text" class="input3" placeholder="输入我的手机号">

        <input type="text" class="input4" placeholder="输入我的手机号1">
        <div class="chamfer"></div>
        <input type="text" class="input3" placeholder="输入我的手机号">

        <input type="text" class="input4" placeholder="输入我的手机号1">
        
        <div class="chamfer"></div>
    </div>
</template>
  
<script>
import inobounce from 'inobounce';
export default {
    name: '',
    props: {
    },
    data() {
        return {
            data: '',
            isWindow:false,
            isDoc:false
        }
    },
    created(){
        let u = navigator.userAgent
        if (u.indexOf('iPhone')>-1){
            inobounce.enable()
        }
    },
    beforeDestroy(){
        inobounce.disable()
    },
    mounted() {
        var _this = this
        // $('window').on('touchmove', function (event) {
        //     console.log('window scroll forbidden')
        //     _this.isWindow = true
        //     event.preventDefault()
        // })
        // $('document').on('touchmove', function (event1) {
        //     console.log('document scroll forbidden')
        //     _this.isDoc = true
        //     event1.preventDefault()
        // })
        // $('body').on('touchmove', function (event2) {
        //     // console.log('body scroll forbidden')
        //     // event2.preventDefault()
        // })
        // $('document'.on('touchmove', function (event) {
        //     event.preventDefault()
        // }))
        // $('body'.on('touchmove', function (event) {
        //     event.preventDefault()
        // }))
        // var obj = document.getElementById('indexcontent')
        // document.addEventListener('touchmove', function (e) {
        //     if (e.target == obj){
        //         return
        //     }
        //     _this.isWindow = true
        //     var e2 = e || window.event;
        //     e2.preventDefault();
        // }, { passive: false })
    },
    beforeRouteUpdate(to, from, next) {
        if (to.query.id) {
            to.meta.keepAlive = false;
        }
        else {
            to.meta.keepAlive = true;
        }
        next()
    },
    watch: {
        $route: {
            handler(n) {
                this.data = n.query.id
            },
            immediate: true, deep: true
        }
    },
    methods: {
        handleTouch(e) {
            e._isScroller = true
        },
        click() {
            this.$router.push({
                path: '/demo',
                query: {
                    id: 1
                }
            })
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
input {
    margin: 0;
    padding: 0;
    color: #fff;
}

.top_thumb {
    width: 100vw;
    height: 100vh;
    transform-origin: top center;
    transform: scale(0.31, 0.31);
    border-radius: 0.18rem;
    overflow: hidden;
}

.thumb {
    box-shadow: 0px 19px 39px 0px rgba(0, 0, 0, 0.7);
    border-radius: 0.06rem;
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    // background-color: red;
    // position: relative;
    z-index: 10000;

}

.indexcontent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: pink;
    -webkit-overflow-scrolling: touch; // 触摸增强
    overflow: scroll;
    // -webkit-overflow-scrolling: auto;
    // overscroll-behavior: none;

    .footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.test1 {
    font-size: 0.2rem;
}

.test2 {
    font-size: 0.22rem;
}

.test3 {
    font-size: 0.24rem;
}

.chamfer {
    width: 300px;
    height: 300px;
    background: #009EEB;
    clip-path: polygon(0 0, 0 300px, 300px 250px, 300px 0);
    // clip-path: polygon(40px 0, calc(100% - 20px) 0, 100% 20px,
    //         100% calc(100% - 20px), calc(100% - 20px) 100%,
    //         20px 100%,
    //         0 calc(100% - 20px),
    //         0 20px);
}

.input-bg {
    margin-top: 0.36rem;
    width: 3.15rem;
    height: 0.68rem;
    border-radius: 0.16rem;
    background-color: #2C2C2EFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.16rem;
    padding-right: 0;
    box-sizing: border-box;
    overflow: hidden;

    .area {
        color: #fff;
        font-size: 0.28rem;
        font-family: 'Link';
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 0.24rem 0.24rem;
        background-image: url('../assets/images/arrow.png');
        padding-right: 0.28rem;
    }

    .clear {
        display: flex;
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.16rem;
        flex-shrink: 0;
    }
}

.input1 {
    flex: 1;
    height: 0.64rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.input-content {
    caret-color: #FF9900FF;
    font-family: 'Link';
    // font-weight: bold;
    height: 0.64rem;
    line-height: 0.64rem;
    background-color: transparent;
    font-size: 0.28rem;
    border: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.input3 {
    margin-top: 50px;
    caret-color: #FF9900FF;
    font-family: 'Link';
    height: 0.28rem;
    line-height: 0.28rem;
    font-size: 0.28rem;
    background-color: palegoldenrod;
    width: 300px;
}

.input4 {
    margin-top: 50px;
    caret-color: #FF9900FF;
    font-family: 'Link';
    font-size: 0.28rem;
    background-color: palegoldenrod;
    width: 300px;
}

.input-content2 {
    caret-color: #FF9900FF;
    font-family: 'Link';
    height: 0.28rem;
    line-height: 0.28rem;
    background-color: transparent;
    font-size: 0.28rem;
    border: none;
    width: 100%;
    background-color: red;
}

input::-webkit-input-placeholder {
    color: #74747AFF;
    font-size: 0.2rem;
}

input::-moz-placeholder {
    color: #74747AFF;
    font-size: 0.2rem;
}

.protocol {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.38rem;

    .link {
        color: #FF9900FF;
        font-size: 0.12rem;
    }
}

.other {
    position: fixed;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    text-align: center;
    color: #808080FF;
    font-size: 0.14rem;
}

.btn_input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    opacity: 0;
}
</style>